import React from "react";

export const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || "https://devserver.easycloud.co.id/api"; // dev
  process.env.REACT_APP_API_BASE_URL || "https://server.easycloud.co.id/api"; //online
// process.env.REACT_APP_API_BASE_URL || "http://192.168.1.124:8084/api"; // aji
// process.env.REACT_APP_API_BASE_URL || "http://192.168.1.124:8443/api"; // aji
// process.env.REACT_APP_API_BASE_URL ||
// "https://deveasycloud.user.cloudjkt01.com/api"; // dewaCloud
// process.env.REACT_APP_API_BASE_URL || "http://192.168.1.32:5000/api"; // diki
// process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export const SERVERCOGS = "https://servercogs.easycloud.co.id/api";
// export const SERVERCOGS = "http://192.168.1.124:8089/api";
export const BASE_CDN =
  "https://res.cloudinary.com/pt-global-infomasi-fortuna-teknotama/image/upload";
export const BASE_SOCKET = "https://cnt.easycloud.co.id";
// export const BASE_SOCKET = "http://192.168.1.124:8086";
export const APIKEY_TINYMCE =
  "xt587zu8ddxvt3up6lnums0hnvjbqsz5u0nr5nhv3q0wt6vf";
export const PrivateKey_FCM =
  "BLXjeG-XOE09Cv9LiqaH6AqHhfhgSPmxXSSEFVILvvNNK8SSbkf9lC_mKDdiOssVDqPtB6iDiJh8L1NCG8DF9FM";
export const ACCESS_TOKEN = "accessToken";
export const TOKEN_FCM = "TOKEN_FCM";
export const ACCESS_TOKEN_ADMIN = "accessTokenAdmin";
export const BASE_FORMAT_DATE = "YYYY-MM-DD";
export const POLL_LIST_SIZE = 30;

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;
//nik
export const NIK_LENGTH = 16;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const MIN_WIDTH = 1023;
export const MIN_AVAIL = 750;

//regex
export const REGEX_PHONE_NUMBER =
  /(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/;
export const REGEX_NUMERIC = /^-?[0-9]*(\.[0-9]*)?$/;
export const REGEX_CURRENCY = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;

//comnpany
export const PROFILE_COMPANY = "PROFILE_COMPANY";
export const FORMAT_DATE_COMPANY = "FORMAT_DATE_COMPANY";
export const TRANSCODE = "TRANSCODE";
//redirect
export const REDIRECT = "REDIRECT";

// hpp
export const HPP = "HPP";

//auth
export const CHANGE_AUTH = "CHANGE_AUTH";
export const ADD_CURRENT_AUTH = "ADD_CURRENT_AUTH";
export const SESSION_ENDED = "SESSION_ENDED";
//tenant
export const ADD_TENANT = "ADD_TENANT";
export const DELETE_TENANT = "DELETE_TENANT";
export const OPEN_TENANT = "OPEN_TENANT";
export const DAFTAR_PENGGUNA = "DAFTAR_PENGGUNA";
export const ARCHIVE_TENANT = "ARCHIVE_TENANT";
export const HAK_AKSES = "HAK_AKSES";
export const COPY_HAK_AKSES = "COPY_HAK_AKSES";
export const PREFERENSI = "PREFERENSI";
export const CLOSE_TENANT = "CLOSE_TENANT";
export const FETCH_TENANT = "FETCH_TENANT";
//mobile
export const CHANGE_WIDTH = "CHANGE_WIDTH";

//theme
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_MODE = "CHANGE_MODE";
export const CHANGE_COLOR = "CHANGE_COLOR";

//master_data
export const ADD_CURRENCY = "ADD_CURRENCY";
export const ADD_STATPERSON = "ADD_STATPERSON";
export const ADD_TIPEPELANGGAN = "ADD_TIPEPELANGGAN";
export const ADD_JASAPENGIRIMAN = "ADD_JASAPENGIRIMAN";
export const ADD_MASTERDATA = "ADD_MASTERDATA";

//panes
export const INIT_TAB = "INIT_TAB";
export const REMOVE_TAB = "REMOVE_TAB";
export const CLEAR_REMOVE_TAB = "CLEAR_REMOVE_TAB";
export const CLOSE_ALL_TAB = "CLOSE_ALL_TAB";
export const CHANGE_CAPTION_TAB = "CHANGE_CAPTION_TAB";

//draft
export const ADD_DRAFT = "ADD_DRAFT";
export const DELETE_DRAFT = "DELETE_DRAFT";
export const ADD_PARENT_DRAFT = "ADD_PARENT_DRAFT";
export const RESET = "RESET";
export const RESET_TAB = "RESET_TAB";
export const ONCHANGE_DRAFT = "ONCHANGE_DRAFT";

//laporan
export const ADD_MODIF_LAPORAN = "ADD_MODIF_LAPORAN";
export const DELETE_MODIF_LAPORAN = "DELETE_MODIF_LAPORAN";
export const ADD_REMOVE_FIELD = "ADD_REMOVE_FIELD";
export const CHANGE_FIELD = "CHANGE_FIELD";
export const SORT_FIELD = "SORT_FIELD";
export const CHANGE_ID_FILTER = "CHANGE_ID_FILTER";
export const CHANGE_PARAMETER = "CHANGE_PARAMETER";
export const CHANGE_OTHERPARAMETER = "CHANGE_OTHERPARAMETER";
export const GENERATE_USED_FIELD = "GENERATE_USED_FIELD";
export const CHANGE_MASTER = "CHANGE_MASTER";
export const CHANGE_FIELD_PIVOT = "CHANGE_FIELD_PIVOT";
export const REPORT_NAME = "REPORT_NAME";

//table react data grid
export const HEIGHT_ROW_COL = 23;

/* =========== module ===========*/

//  master data
export const ADD_PELANGGAN = "ADD_PELANGGAN";
export const ON_LOAD_PAGE = "ON_LOAD_PAGE";
export const ADD_AKUN = "ADD_AKUN";
export const ON_ERROR = "ON_ERROR";

export const KEY = "c2hhcXVlZW5hQWlsZWVuVGF1cmVuc2lh";
export const LANGUAGE = "LANGUAGE";

// transaction
export const DISC_ACCOUNT = "DISC_ACCOUNT";
export const UPDATE_DISC_ACCOUNT = "UPDATE_DISC_ACCOUNT";
export const RESET_DISC_ACCOUNT = "RESET_DISC_ACCOUNT";
export const DELETE_DISC_ACCOUNT = "DELETE_DISC_ACCOUNT";
export const DELETE_DISC_ACCOUNT_BYQUE = "DELETE_DISC_ACCOUNT_BYQUE";
export const DELET_DISC_ACCOUNT_BYINV = "DELET_DISC_ACCOUNT_BYINV";
//billing
export const BILL_UNPAYED = "BILL_UNPAYED";
//admin
export const INVOICE_ALERT = "INVOICE_ALERT";
export const REQ_INV_ALERT = "REQ_INV_ALERT";
//template
export const UPDATE_EDIT_MODE = "svgRender/UPDATE_EDIT_MODE";
export const SET_MOUSE_START_POSITION =
  "layoutBuilder/SET_MOUSE_START_POSITION";
export const SELECT_OBJECTS = "svgRender/SELECT_OBJECTS";
export const DESELECT_ALL_OBJECTS = "svgRender/DESELECT_ALL_OBJECTS";
export const DESELECT_ALL_OBJECTS_EXEPT =
  "svgRender/DESELECT_ALL_OBJECTS_EXEPT";
export const MOVE_OBJECT = "svgRender/MOVE_OBJECT";
export const SET_SELECTED_OBJECTS_EDIT_START_POSITION =
  "svgRender/SET_SELECTED_OBJECTS_EDIT_START_POSITION";
export const RESIZE_OBJECTS = "svgRender/RESIZE_OBJECTS";
export const ROTATE_OBJECT = "svgRender/ROTATE_OBJECT";
export const SET_VERTICAL_ALIGN = "textTool/SET_VERTICAL_ALIGN";
export const SET_TEXT_ALIGN = "textTool/SET_TEXT_ALIGN";
export const SET_WORD_WRAP = "textTool/SET_WORD_WRAP";
export const SET_FILL_COLOR = "textTool/SET_FILL_COLOR";
export const SET_STROKE_COLOR = "textTool/SET_STROKE_COLOR";
export const SET_STROKE_WIDTH = "textTool/SET_STROKE_WIDTH";
export const SET_NEW_FIGURE_DRAG_DATA = "laoutBuilder/SET_NEW_FIGURE_DRAG_DATA";
export const ADD_NEW_OBJECT = "svgRender/ADD_NEW_OBJECT";
export const UPDATE_OBJECT_TEXT = "svgRender/UPDATE_OBJECT_TEXT";
export const LOAD_OBJECT = "svgRender/LOAD_OBJECT";
export const CLEAR_OBJECT = "svgRender/CLEAR_OBJECT";
export const DELETE_OBJECT = "svgRender/DELETE_OBJECT";
export const RESIZE_PAPER = "svgRender/RESIZE_PAPER";
export const SET_TABLE_NAME = "svgRender/SET_TABLE_NAME";
export const SET_TABLE_FIELDNAME = "svgRender/SET_TABLE_FIELDNAME";
export const SHOW_PREVIEW = "svgRender/SHOW_PREVIEW";
export const USE_BACKGROUND = "svgRender/USE_BACKGROUND";
export const USE_SAMPLEDATA = "svgRender/USE_SAMPLEDATA";
export const SET_FRAME = "textTool/SET_FRAME";
export const SET_VISIBLEMASTER = "textTool/SET_VISIBLEMASTER";
export const SET_FONT = "font/SET_FONT";
export const SET_FRACTION = "textTool/SET_FONT";
export const SET_FONTCOLOR = "font/SET_FONTCOLOR";
export const SET_TEXT = "textTool/SET_TEXT";
export const SET_MODE = "textTool/SET_MODE";
export const SET_FRACTIONDIGIT = "textTool/SET_FRACTIONDIGIT";
export const SET_POSITION_RULER = "textTool/SET_POSITION_RULER";
export const ADD_RULER = "svgRender/ADD_RULER";
export const REMOVE_RULER = "svgRender/REMOVE_RULER";
export const ALL_CHART = "ALL_CHART";
//chat
export const OPEN_CHAT = "OPEN_CHAT";
export const OPEN_GROUP = "OPEN_GROUP";
export const ADD_GROUP = "ADD_GROUP";
export const NEW_CHAT = "NEW_CHAT";
export const CLOSE_CHAT = "CLOSE_CHAT";
export const RESET_CHAT = "RESET_CHAT";
export const SEND_CHAT = "SEND_CHAT";
export const COLLAPSE = "COLLAPSE";
export const SHORTBULAN = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];
export const BULAN = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const BULAN_ENGLISH = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * @description api by id
 */
export const accntPembelian = 13;
export const accntPenjualan = 8;

export const typePemasok = 1;
export const typePelanggan = 0;
export const typeChargetoVendor = "7,8,13";

export const dpPembelian = 1;
export const dpPenjualan = 2;

export const typeDaftarAkun = "7,8";
export const typeAkunBank = 7;
export const typeAkunPenyesuaian = "9,10,16,17,18,19,20,21";
export const typeAkunLabaDitahan = 16;
export const typeAkunSelisihKoma = "17,19,21";
export const typeAkunHutang = 13;
export const typeAkunPiutang = 8;
export const typeAkunPenjualan = "17,21";
export const typeAkunPersediaan = 9;
export const typeAkunReturPenjualan = "17,21";
export const typeAkunDiskonPenjualan = "17,21";
export const typeAkunHPP = 18;
export const typeAkunReturPembelian = 9;
export const typeAkunBeban = "19,20";
export const typeAkunWIP = 9;
export const typeAkunVariant = "14,17,18,19,20,21";
export const typeAkunBiayaLain = "17,18,19,20,21";
export const typeAkunPenerimaanBarang = 14;
export const typeAkunBarangTerkirim = 10;
export const typeAkunPembiayaanPesanan = "9,10,16,17,18,19,20,21";
export const typeAkunSelisihBarangBlmTertagih = "17,18,19,20,21";

export const typeDiskonPenjualan = "17,18,21";
export const typeDiskonPembelian = 18;

export const labaRugiTerealisir = "19,20";
export const labaRugiTdkTerealisir = "19,20";

export const uangMukaPembelian = 8;
export const uangMukaPenjualan = 13;
export const akunBiayaProduksi = "18,19,20";
/**
 * @description template
 */
export const initialStateTemplate = {
  layoutBuilder: {
    newFigureDragData: null,
    mouseStartPosition: { x: null, y: null },
  },
  svgRender: {
    editMode: null,
    mousePosition: { x: null, y: null },
    selectedObjectsId: [],
    objectsById: [],
    objectsByHash: {},
    sizePaper: {
      name: "A5",
      orientation: "Landscape",
      height: 148,
      width: 210,
      detailCount: 10,
      LeftMargin: 30,
      RightMargin: 30,
      TopMargin: 30,
      BottomMargin: 30,
      tableDetail: "details",
    },
    preview: true,
    usedBackground: false,
    usedSampleData: false,
    usedExpense: false,
    ruler: [],
  },
};

export const disableColor = "#52575d";

export const defaultDepartment = {
  contact_name: "",
  deptid: 1,
  deptname: "NON DEPARTMENT",
  deptno: "0",
  description: "",
  indentlevel: 0,
  issubdept: true,
  parentdeptid: null,
  subdeptid: null,
  suspended: false,
  tipe: 0,
};

export const defaultProject = {
  header: false,
  indentlevel: 0,
  linkedcustomer: null,
  managername: null,
  parentprojectid: null,
  percentcompleted: null,
  projectid: 1,
  projectname: "Non Project",
  projectno: "0",
  start_date: "2021-12-01",
  subproject: false,
  subprojectid: null,
  suspended: false,
};

export const defaultWarehouse = {
  address1: "x",
  address2: "x",
  address3: "x",
  branchcodewh: null,
  description: "x",
  name: "CENTER",
  pic: "x",
  warehouseid: 1,
};

export const defaultSatuan = {
  unitid: 13,
  unitname: "PCS",
};

export const defaultbounds = {
  width: 117,
  height: 20,
  x: 488,
  y: 111,
  font: {
    color: "black",
    name: "Arial",
    size: 12,
    bold: false,
    italic: false,
    underline: false,
  },
  fontDetail: {
    color: "black",
    name: "Arial",
    size: 12,
    bold: false,
    italic: false,
    underline: false,
  },
  frame: { top: "solid", right: "solid", bottom: "solid", left: "solid" },
  framedetail: { top: "none", right: "solid", bottom: "none", left: "solid" },
  textProps: { textAlign: "center", verticalAlign: "middle" },
  textPropsdetail: { textAlign: "left", verticalAlign: "middle" },
};

export const Eula = (
  <React.Fragment>
    <body></body>
    <ol>
      <li>Ruang Lingkup Perjanjian.</li>
      <desc>
        <b>
          Perjanjian ini terkait dengan penggunaan Anda atas perangkat lunak
          Easy Cloud Beta dan semua layanannya. Easy Cloud merupakan software
          akuntansi berbasis cloud/online dari merek dagang Easy System® yang
          dimiliki dan dikembangkan seluruhnya oleh PT Global Informasi Fortuna
          Teknotama (GIFT).
        </b>
        Dan perjanjian ini sah secara hukum antara Anda (baik pribadi atau
        perusahaan) dengan PT Global Informasi Fortuna Teknotama (GIFT).
        Pengguna Easy Cloud Beta diberikan hak berdasarkan Perjanjian ini untuk
        tujuan pengujian dan memberi masukan dan umpan balik lainnya kepada Easy
        Cloud mengenai antarmuka platform aplikasi, layanan, fitur dan atau
        fungsi Easy Cloud yang berhubungan dengan akun yang ada, seperti yang
        dijelaskan lebih lengkap dalam Formulir Perintah Pengujian Beta ("Produk
        Beta"). Perjanjian ini mencakup semua Produk Beta yang disediakan untuk
        pengguna Easy Cloud Beta oleh Easy Cloud, termasuk Produk Beta apa pun
        yang secara khusus diidentifikasi oleh Easy Cloud sebagai "Beta" atau
        tahap pengembangan yang sama. Easy Cloud mempertahankan kebijaksanaan
        tunggal dan mutlak tentang Produk Beta akan tersedia untuk Pengguna Easy
        Cloud Beta dalam suatu jangka waktu. Meskipun pengguna Easy Cloud Beta
        tidak diharuskan untuk menggunakan atau mengaktifkan Produk Beta apa
        pun, jika Pengguna Easy Cloud Beta memilih untuk melakukannya, maka
        penggunaan dan akses pengguna Easy Cloud Beta ke Produk Beta tersebut
        harus tunduk pada persyaratan perjanjian ini.
      </desc>
      <li>Pembayaran Biaya.</li>
      <desc>
        Easy Cloud berhak membebankan biaya untuk penggunaan dan akses ke Produk
        Beta atau dapat menyediakan Produk Beta tanpa biaya. Easy Cloud berhak
        untuk mulai membebankan atau merevisi jumlah biaya kapan saja atas
        kebijakannya sendiri, berdasarkan perjanjian ini atau setelah produk
        beta tersedia secara luas dan dimasukkan ke dalam layanan Easy Cloud.
      </desc>
      <li>Hak Penggunaan Terbatas.</li>
      <desc>
        Untuk jangka waktu Perjanjian, Easy Cloud memberikan kepada Pengguna
        Easy Cloud Beta hak terbatas, tidak dapat dialihkan, non-eksklusif, dan
        dapat dibatalkan untuk mengakses dan menggunakan Produk Beta sehubungan
        dengan Akun yang Ada untuk menguji fungsinya dan memberikan Umpan Balik
        ke Easy Cloud. Easy Cloud menyimpan dan mempertahankan kontrol fisik
        atas Produk Beta dan hanya membuat Produk Beta tersedia untuk diakses
        dan digunakan oleh Pengguna Easy Cloud Beta melalui Internet dengan web
        browser atau perangkat mobile.{" "}
        <b>
          Tidak disebutkan dalam perjanjian ini yang mewajibkan Easy Cloud untuk
          mengirimkan atau menyediakan salinan program komputer atau kode dari
          Produk Beta kepada Pengguna, baik dalam bentuk kode objek atau kode
          sumber. Easy Cloud berhak, atas kebijakannya sendiri, untuk mencabut
          akses dan penggunaan Produk Beta kapan saja.
        </b>
        Lebih lanjut, Easy Cloud berhak untuk memasukkan akun yang ada
        sebagaimana diperlukan untuk (i) memantau atau mengukur penggunaan
        Produk Beta; (ii) memvalidasi fitur atau fungsi tertentu dari Produk
        Beta; dan (iii) untuk memberikan layanan atau dukungan yang diperlukan
        untuk mempertahankan Produk Beta.
      </desc>
      <li>Pembatasan Penggunaan.</li>
      <desc>
        Pengguna tidak boleh menyewakan, mendistribusikan, atau menjual kembali
        Produk Beta, atau menggunakan Produk Beta sebagai dasar untuk
        mengembangkan solusi kompetitif (atau mengontrak pihak ketiga untuk
        melakukannya), atau menghapus atau mengubah salah satu logo, merek
        dagang, paten atau pemberitahuan hak cipta, kerahasiaan atau kepemilikan
        legenda atau pemberitahuan atau tanda lain yang ada pada atau di dalam
        Produk Beta.
      </desc>
      <li>Umpan balik. </li>
      <desc>
        Atas permintaan yang wajar oleh Easy Cloud, Pengguna Easy Cloud Beta
        setuju untuk memberikan saran, permintaan penyempurnaan, dan rekomendasi
        (secara individu dan kolektif) terkait Produk Beta. Umpan balik termasuk
        menginformasikan Easy Cloud tentang kinerja, kemudahan penggunaan, fitur
        yang mungkin hilang, dan setiap bug yang ditemukan saat penggunaan
        Produk Beta. Easy Cloud dapat menghubungi pengguna Easy Cloud Beta dan
        pengguna Easy Cloud Beta setuju untuk meluangkan waktu yang cukup untuk
        mendiskusikan Produk Beta dengan Easy Cloud jika diminta. Easy Cloud
        dapat, tanpa batasan atau penggunaan biaya, memodifikasi dan memasukkan
        umpan balik ini ke dalam Produk Beta dan produk atau layanan Easy Cloud
        lainnya tanpa batasan apa pun dan tanpa pembayaran apa pun.
      </desc>
      <li>Kekayaan intelektual. </li>
      <desc>
        Semua pihak mengakui bahwa perjanjian ini tidak mengalihkan hak,
        kepemilikan, atau kepentingan apa pun atas hak kekayaan intelektual
        kepada pihak lain. Easy Cloud mempertahankan semua hak, kepemilikan, dan
        kepentingan di dalam dan untuk semua paten, penemuan, hak cipta, merek
        dagang, nama domain, rahasia dagang, pengetahuan dan hak kekayaan
        intelektual dan atau kepemilikan lainnya (secara kolektif, "Hak Kekayaan
        Intelektual"). Hak terbatas yang diberikan kepada Pengguna untuk
        mengakses dan menggunakan Produk Beta berdasarkan perjanjian ini tidak
        memberikan hak tambahan apa pun dalam Produk Beta, atau di dalam atau
        untuk Hak atas Kekayaan Intelektual apa pun yang terkait dengannya.
        Tunduk hanya pada hak terbatas untuk mengakses dan menggunakan Produk
        Beta seperti yang secara tegas disediakan di sini, semua hak,
        kepemilikan dan kepentingan dalam dan pada Produk Beta dan semua
        perangkat keras, perangkat lunak dan komponen lain dari atau yang
        digunakan untuk menyediakan Produk Beta, termasuk semua Hak atas
        Kekayaan Intelektual terkait, akan tetap terus dan dimiliki secara
        eksklusif oleh Easy Cloud.
      </desc>
      <li>Jangka Waktu Perjanjian dan Pembaruan. </li>
      <desc>
        Perjanjian ini akan dimulai pada tanggal mulai pengujian Beta dan akan
        berakhir pada tanggal akhir pengujian Beta, masing-masing sebagaimana
        ditetapkan dalam Formulir Pesanan Pengujian Beta ("Masa Berlaku Awal").
        Setelah kedaluwarsa, perjanjian ini akan diperpanjang secara otomatis
        untuk periode satu (1) bulan berikutnya hingga diakhiri oleh salah satu
        pihak (masing-masing "Jangka Waktu Perpanjangan" dan, secara kolektif
        dengan Jangka Waktu Awal, Jangka Waktu").
      </desc>
      <li>Penghentian dan Kedaluwarsa. </li>
      <desc>
        Salah satu pihak dapat menghentikan perjanjian ini kapan saja, untuk
        alasan apa pun atau tanpa alasan, dan atas kenyamanan salah satu pihak
        dengan memberikan pemberitahuan tertulis kepada pihak lainnya. Atas
        pengakhiran perjanjian ini, penguna akan memberi tahu para pengguna di
        bawahnya bahwa akses mereka ke Produk Beta telah dihentikan, dan Easy
        Cloud dapat menahan, menghapus atau membuang konten, data, atau
        informasi lain apa pun yang diposting atau diunggah pengguna ke sistem
        Easy Cloud selama menggunakan Produk Beta. Setelah penghentian atau
        kedaluwarsa perjanjian ini,{" "}
        <b>
          Easy Cloud tidak berkewajiban untuk menyimpan, menjaga, atau
          memberikan salinan konten, Database, atau informasi lain apa pun yang
          disediakan kepada pengguna saat menggunakan Produk Beta.
        </b>
        Jika tidak dihentikan lebih awal, kewajiban pengguna berdasarkan
        perjanjian ini akan berakhir setelah perjanjian ini dihentikan; asalkan
        hal tersebut di atas tidak membatasi hak Easy Cloud sesuai dengan bagian
        5 yang terkait dengan Umpan Balik yang diberikan sebelum atau setelah
        penghentian tersebut. Bagian 2, 5, 6, 11, dan 13 sampai 16, dan semua
        kewajiban di dalamnya, akan tetap berlaku setelah perjanjian ini
        dihentikan.
      </desc>
      <li>Penangguhan Layanan.</li>
      <desc>
        Easy Cloud dapat segera menangguhkan akses pengguna atau akses salah
        satu pengguna di bawahnya untuk menggunakan Produk Beta jika pengguna
        atau pengguna di bawahnya melanggar ketentuan, asalkan penangguhan hanya
        akan berlanjut selama waktu yang diperlukan bagi pengguna untuk
        memperbaiki pelanggaran.
      </desc>
      <li>Informasi Rahasia. </li>
      <desc>
        Pengguna mengakui dan menyetujui bahwa partisipasi dalam pengujian Beta
        berdasarkan perjanjian ini akan mengakibatkan Easy Cloud mengungkap
        informasi rahasia, kepemilikan, dan informasi rahasia dagang tertentu
        terkait dengan Produk Beta dan Easy Cloud ("Informasi Rahasia").
        Informasi Rahasia tersebut termasuk, namun tidak terbatas pada, fitur,
        kegunaan, dan keberadaan Produk Beta, dan pengetahuan apa pun, rahasia
        dagang, program komputer, kode sumber, diagram alir, diagram, manual,
        skema, alat pengembangan, spesifikasi, desain dokumen, informasi
        pemasaran, informasi keuangan, rencana bisnis atau laporan yang
        disediakan untuk pengguna. Pengguna setuju bahwa tidak akan, tanpa
        persetujuan tertulis sebelumnya dari Easy Cloud, mengungkap informasi
        rahasia apa pun atau bagian apa pun kepada pihak ketiga mana pun,
        kecuali sejauh informasi rahasia tersebut (a) tersedia atau secara umum
        tersedia untuk publik selain karena tindakan atau kelalaian oleh
        pengguna; (b) diterima secara sah oleh pengguna dari pihak ketiga yang
        tidak tunduk pada kewajiban kerahasiaan sehubungan dengan hal tersebut
        dan tanpa batasan untuk penggunaannya; atau (c) dikembangkan secara
        independen oleh pengguna tanpa bergantung pada informasi rahasia apa
        pun. Pada penghentian perjanjian ini atau kapan saja atas permintaan
        Easy Cloud, pengguna akan mengembalikan semua informasi rahasia yang
        dimilikinya kepada Easy Cloud dan selanjutnya setuju untuk tidak akan
        menggandakan, menerjemahkan, memodifikasi, menyalin, mencetak,
        membongkar, mendekompilasi, atau merusak produk beta atau informasi
        rahasia apa pun.
      </desc>
      <li>Integrasi Alat Pihak Ketiga.</li>
      <desc>
        Jika berlaku, satu atau lebih produk beta dapat berintegrasi dengan
        layanan pihak ketiga. Pengguna Easy Cloud Beta dengan ini menyetujui
        untuk membagikan informasi dalam Produk Beta dengan layanan pihak ketiga
        ini dan menyatakan bahwa ia memiliki setiap dan semua persetujuan yang
        diperlukan untuk melakukannya.
      </desc>
      <li>Penafian Jaminan.</li>
      <desc>
        PRODUK BETA DIBERIKAN "SEBAGAIMANA ADANYA". EASY CLOUD TIDAK MEMBUAT
        PERNYATAAN ATAU JAMINAN, TERSURAT MAUPUN TERSIRAT, TENTANG PRODUK BETA,
        TERMASUK PERNYATAAN BAHWA LAYANAN DI BAWAH INI AKAN TANPA GANGGUAN ATAU
        BEBAS DARI KESALAHAN. SEJAUH DIIZINKAN BERDASARKAN HUKUM YANG BERLAKU,
        EASY CLOUD MENAFIKAN SETIAP JAMINAN TERSIRAT ATAU HUKUM, TERMASUK
        JAMINAN TERSIRAT ATAS JUDUL, NONPELANGGARAN, DAPAT DIPERDAGANGKAN, ATAU
        KESESUAIAN UNTUK TUJUAN TERTENTU MENGENAI PRODUK BETA. UNTUK MENGHINDARI
        KERAGUAN, SEMUA PRODUK BETA DISEWAKAN, AKAN MEMILIKI CELA, DAN TIDAK
        DIHARAPKAN BEROPERASI PADA TINGKAT KINERJA ATAU KOMPATIBILITAS PRODUK
        FINAL ATAU PRODUK YANG UMUM TERSEDIA. PRODUK BETA MUNGKIN TIDAK
        BEROPERASI SECARA AKURAT DAN DAPAT DIUBAH SECARA LANGSUNG SEBELUM
        TERSEDIA UNTUK PUBLIK ATAU DITARIK SETIAP SAAT. KARENA ITU, AKSES DAN
        PENGGUNAAN PRODUK BETA SEPENUHNYA MENJADI RISIKO PENGGUNA SENDIRI. EASY
        CLOUD DALAM KEADAAN APA PUN TIDAK AKAN BERTANGGUNG JAWAB ATAS KERUSAKAN
        ATAU APA PUN YANG TIMBUL KARENA PENGGUNAAN ATAU KETIDAKMAMPUAN
        MENGGUNAKAN PRODUK BETA, MESKIPUN EASY CLOUD TELAH DIBERITAHU TENTANG
        KEMUNGKINAN KERUSAKAN TERSEBUT. ANDA DIANJURKAN UNTUK MENGAMANKAN DATA
        PENTING, BERHATI-HATI DAN TIDAK MENGANDALKAN FUNGSI ATAU KINERJA YANG
        BAIK DARI PRODUK BETA APA PUN.
      </desc>
      <li>Ganti rugi. </li>
      <desc>
        Pengguna setuju untuk mengganti rugi dan membebaskan Easy Cloud,
        pejabat, direktur, dan karyawannya dari kerugian apa pun (termasuk biaya
        pengacara) yang diakibatkan oleh klaim pihak ketiga terkait akses,
        penggunaan, atau penyalahgunaan Produk Beta oleh akses pengguna (atau
        pengguna di bawahnya), penggunaan atau penyalahgunaan Produk Beta, atau
        tindakan atau kelalaian dari pengguna atau pengguna di bawahnyayang
        melanggar Perjanjian ini.
      </desc>
      <li>Penyelesaian Sengketa, Hukum yang Mengatur dan Tempat. </li>
      <desc>
        Para pihak akan berusaha untuk menyelesaikan setiap perselisihan terkait
        dengan perjanjian ini melalui itikad baik, negosiasi informal. Jika
        negosiasi awal tidak menyelesaikan sengketa, para pihak akan memilih
        mediator yang disepakati bersama di lokasi yang disepakati bersama untuk
        mencoba menyelesaikan sengketa. Jika mediasi gagal menyelesaikan
        sengketa, salah satu pihak dapat mengajukan tindakan di pengadilan di
        Jakarta Barat dan masing-masing pihak tunduk ke yurisdiksi dan tempat
        pengadilan yang berlaku. Hukum wilayah Jakarta mengatur perjanjian ini.
        Pihak yang menang dalam proses pengadilan apa pun dapat berupaya untuk
        mendapatkan kembali biaya hukumnya. Setiap pelanggaran atas kewajiban
        kerahasiaan dalam perjanjian ini, atau penggunaan layanan yang tidak sah
        atau penggunaan kekayaan intelektual salah satu pihak oleh pihak lain,
        dapat menyebabkan kerusakan yang tidak dapat diperbaiki.
      </desc>
      <li>Kepatuhan terhadap Hukum dan Nasihat Hukum. </li>
      <desc>
        Masing-masing pihak harus mematuhi semua undang-undang, aturan, atau
        peraturan yang berlaku untuk aktivitas pihak tersebut sehubungan dengan
        perjanjian ini, termasuk undang-undang kontrol ekspor Indonesia yang
        berlaku untuk aplikasi dan yang dapat melarang penggunaan aplikasi di
        negara-negara yang terkena sanksi atau embargo tertentu. Easy Cloud
        tidak akan memberikan nasihat hukum apa pun kepada pengguna terkait
        kepatuhan dengan privasi data atau undang-undang, hukum, atau regulasi
        lainnya di yurisdiksi tempat pengguna menggunakan Produk Beta ("Hukum").
        Semua pihak memahami dan menyetujui bahwa tidak semua fitur, fungsi, dan
        kemampuan Produk Beta dapat digunakan di semua yurisdiksi dan pengguna
        mengetahui bahwa fitur, fungsi, dan kapabilitas tertentu mungkin perlu
        dikonfigurasi secara berbeda atau tidak digunakan di yurisdiksi tertentu
        untuk mematuhi hukum setempat yang berlaku, dan di yurisdiksi tertentu
        persetujuan mungkin perlu diperoleh dari individu yang mengirimkan data
        melalui Produk Beta untuk tujuan, penyimpanan, distribusi, akses, dan
        penggunaan data yang dikirimkan (“Keputusan Penggunaan Lokal ”).
        Pengguna bertanggung jawab atas Keputusan Penggunaan Lokal dan Easy
        Cloud melepaskan semua tanggung jawab atas Keputusan Penggunaan Lokal.
      </desc>
      <li>Lain-lain. </li>
      <desc>
        Perjanjian ini tidak menciptakan kemitraan, hubungan agensi, atau usaha
        bersama antara para pihak terkait. Setiap pengalihan perjanjian ini oleh
        pengguna secara keseluruhan atau sebagian tanpa persetujuan tertulis
        sebelumnya dari Easy Cloud akan dianggap batal, kecuali penugasan kepada
        suksesor yang bukan pesaing Easy Cloud yang dibuat sehubungan dengan
        merger atau semua penjualan atau secara substansial semua aset atau
        saham pengguna atau kepada afiliasi. Jika Perjanjian ini diterjemahkan
        ke dalam bahasa selain bahasa Indonesia, terjemahan tersebut hanya untuk
        kemudahan saja, dan versi bahasa Indonesia yang tetap akan mengatur.
        Jika ada ketentuan dalam Perjanjian ini yang tidak dapat diberlakukan,
        ketentuan tersebut akan diubah untuk membuatnya dapat diberlakukan
        sejauh mungkin untuk memengaruhi niat para pihak dan ketentuan lainnya
        tidak akan terpengaruh. Kegagalan Easy Cloud untuk menegakkan hak
        berdasarkan perjanjian ini tidak akan berlaku sebagai pengabaian hak
        tersebut atau kemampuan untuk menegaskan hak tersebut di kemudian hari
        terkait dengan situasi tertentu yang terlibat. Perjanjian ini mencakup
        semua jadwal dan pameran yang dilampirkan di sini. Dokumen tersebut
        mencakup seluruh perjanjian antara pengguna dan Easy Cloud sehubungan
        dengan pokok bahasan di sini dan menggantikan semua pernyataan,
        perjanjian dan pemahaman sebelumnya, baik tertulis atau lisan.
        Perjanjian ini hanya dapat diubah atau dimodifikasi dengan instrumen
        tertulis yang ditandatangani sebagaimana mestinya. Semua pemberitahuan
        yang akan diberikan oleh Easy Cloud kepada Pengguna berdasarkan
        perjanjian ini dapat dikirimkan secara tertulis (a) oleh layanan
        pengiriman kilat yang diakui secara nasional ("Kurir") atau Pos
        Indonesia ke alamat surat kontak yang diberikan oleh pengguna dalam
        bentuk apa pun; atau (b) surat elektronik ke alamat surat elektronik
        yang disediakan untuk pemilik akun Pengguna yang ada. Pengguna harus
        memberi tahu Easy Cloud secara tertulis melalui kurir atau Email ke
        alamat berikut: PT. Global Informasi Fortuna Teknotama,Jl. Haji lebar
        kavling DKI no 9D Meruya Utara, Jakarta - Indonesia. Semua pemberitahuan
        akan dianggap telah diberikan segera setelah pengiriman melalui surat
        elektronik, atau jika dikirimkan setelah diterima atau, jika sebelumnya,
        dua (2) hari kerja setelah disimpan melalui pos atau dengan kurir
        sebagaimana diizinkan di atas. Anda tidak dapat mengalihkan Perjanjian
        ini tanpa persetujuan tertulis sebelumnya dari Easy Cloud. Tunduk pada
        hal tersebut di atas, perjanjian akan mengikat para pihak dan
        administrator, penerus, dan penerima haknya masing-masing.
      </desc>
    </ol>
  </React.Fragment>
);

export const TidakMenyusut = 1;
export const MetodeGarisLurus = 2;
export const MetodeDoubleDeclining = 3;

export const sizeChat = [
  5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
];
export const nameFont = [
  "Agency FB",
  "Albertina",
  "Antiqua",
  "Architect",
  "Arial",
  "BankFuturistic",
  "BankGothic",
  "Blackletter",
  "Blagovest",
  "Calibri",
  "Comic Sans MS",
  "Consolas",
  "Courier",
  "Cursive",
  "Decorative",
  "Fantasy",
  "Fraktur",
  "Frosty",
  "Garamond",
  "Georgia",
  "Helvetica",
  "Impact",
  "Minion",
  "Modern",
  "Monospace",
  "Open Sans",
  "Palatino",
  "Perpetua",
  "Roman",
  "Sans-serif",
  "Serif",
  "Script",
  "Swiss",
  "Tahoma",
  "Times",
  "Times New Roman",
  "Tw Cen MT",
  "Verdana",
];

export const TOOLS_METODE = [
  "Persentase",
  "Persentase Dibulatkan (Atas)",
  "Persentase Dibulatkan (Bawah)",
  "Penjumlahan Nilai",
  "Penjumlahan Nilai Dibulatkan (Atas)",
  "Penjumlahan Nilai Dibulatkan (Bawah)",
  "Pengurangan Nilai",
  "Pengurangan Nilai Dibulatkan (Atas)",
  "Pengurangan Nilai Dibulatkan (Bawah)",
];

export const PENYESUAIAN_HARGA = [
  { name: "Semua Harga Jual", value: 0, field: "all" },
  { name: "Harga Baru 1", value: 1, field: "price1" },
  { name: "Harga Baru 2", value: 2, field: "price2" },
  { name: "Harga Baru 3", value: 3, field: "price3" },
  { name: "Harga Baru 4", value: 4, field: "price4" },
  { name: "Harga Baru 5", value: 5, field: "price5" },
  { name: "Diskon Barang", value: 6, field: "itemdiscpc" },
  { name: "Min Harga Jual", value: 7, field: "minsellprice" },
  { name: "Max Harga Jual", value: 8, field: "maxsellprice" },
  { name: "Min Harga Beli", value: 9, field: "minpurcprice" },
  { name: "Max Harga Beli", value: 10, field: "maxpurcprice" },
];
export const NILAI_ASAL = [
  { name: "Nilai Sekarang", value: 1, field: "allvalue" },
  { name: "Biaya Aktual", value: 2, field: "standardcost" },
  { name: "Semua Nilai Terakhir", value: 3, field: "alllastvalue" },
  { name: "Harga Lama 1", value: 5, field: "oldprice1" },
  { name: "Harga Lama 2", value: 6, field: "oldprice2" },
  { name: "Harga Lama 3", value: 7, field: "oldprice3" },
  { name: "Harga Lama 4", value: 8, field: "oldprice4" },
  { name: "Harga Lama 5", value: 9, field: "oldprice5" },
  { name: "Harga Baru 1", value: 10, field: "price1" },
  { name: "Harga Baru 2", value: 11, field: "price2" },
  { name: "Harga Baru 3", value: 12, field: "price3" },
  { name: "Harga Baru 4", value: 13, field: "price4" },
  { name: "Harga Baru 5", value: 14, field: "price5" },
  { name: "Harga Min Jual Lama", value: 15, field: "oldminsellprice" },
  { name: "Harga Max Jual Lama", value: 16, field: "oldmaxsellprice" },
  { name: "Harga Min Jual Baru", value: 17, field: "oldminpurcprice" },
  { name: "Harga Max Jual Baru", value: 18, field: "oldmaxpurcprice" },
];

export const TOOLS_NILAIASAL_BIAYA = [
  "Biaya Standar Awal",
  "Biaya Standar Terakhir",
  "Biaya Standar Baru",
];

export const TOOLS_PEMBULATAN = [
  "10",
  "100",
  "1000",
  "10000",
  "100000",
  "1000000",
  "10000000",
  "100000000",
];
